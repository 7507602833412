var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: {
                placeholder: _vm.$t("gameSetting.fb_type"),
                clearable: ""
              },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            _vm._l(_vm.typeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.inputPlayerId") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.uid,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "uid", $$v)
              },
              expression: "listQuery.uid"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("gameSetting.inputReportUid") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.reportUid,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "reportUid", $$v)
              },
              expression: "listQuery.reportUid"
            }
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.rangeTime,
              callback: function($$v) {
                _vm.rangeTime = $$v
              },
              expression: "rangeTime"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("gameSetting.fb_state"),
                clearable: ""
              },
              model: {
                value: _vm.listQuery.state,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "state", $$v)
                },
                expression: "listQuery.state"
              }
            },
            _vm._l(_vm.states, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: {
                  label: item.name + "(" + item.key + ")",
                  value: item.key
                }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-download" },
              on: { click: _vm.handleExport }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.export")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("agents.id"),
              prop: "id",
              sortable: "custom",
              align: "center",
              width: "100px",
              "class-name": _vm.getSortClass("id")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("user.playerId"),
              width: "100px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.uid))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_type"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTypeValue(scope.row.type)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_reportUid"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.reportUid) +
                          " " +
                          _vm._s(_vm.getForbidInfo(scope.row.reportUid))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_content"),
              width: "270px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.content))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_reportTypes"),
              width: "200px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getReportTypes(scope.row.reportTypes)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_mail"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.mail))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_images"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.showReportDetail(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("gameSetting.fb_showDetail")) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_state"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.getState(scope.row.state)))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_createTime"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTime(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_updateTime"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTime(scope.row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: _vm.$t("default.operate"),
              "min-width": "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.state !== 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleReport(scope.row, 0)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("default.unhandler")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    scope.row.state !== 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleReport(scope.row, 1)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("default.pass")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    scope.row.state !== 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleReport(scope.row, 2)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("default.reject")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                model: _vm.reportDetail,
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("agents.id"), prop: "id" } },
                [
                  _c("el-input", {
                    attrs: { readonly: true, type: "text" },
                    model: {
                      value: _vm.reportDetail.id,
                      callback: function($$v) {
                        _vm.$set(_vm.reportDetail, "id", $$v)
                      },
                      expression: "reportDetail.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("user.playerId"), prop: "uid" } },
                [
                  _c("el-input", {
                    attrs: { readonly: true, type: "text" },
                    model: {
                      value: _vm.reportDetail.uid,
                      callback: function($$v) {
                        _vm.$set(_vm.reportDetail, "uid", $$v)
                      },
                      expression: "reportDetail.uid"
                    }
                  })
                ],
                1
              ),
              _vm.reportDetail.reportUid !== 0
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("gameSetting.fb_reportUid"),
                        prop: "reportUid"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { readonly: true, type: "text" },
                        model: {
                          value: _vm.reportDetail.reportUid,
                          callback: function($$v) {
                            _vm.$set(_vm.reportDetail, "reportUid", $$v)
                          },
                          expression: "reportDetail.reportUid"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("gameSetting.fb_images"),
                    prop: "images"
                  }
                },
                [
                  _vm._l(_vm.reportDetail.images, function(item) {
                    return [
                      _c("el-popover", { key: item }, [
                        _c("img", {
                          staticStyle: { width: "400px", height: "850px" },
                          attrs: { src: item }
                        }),
                        _c("img", {
                          staticStyle: { width: "100px", height: "210px" },
                          attrs: { slot: "reference", src: item },
                          slot: "reference"
                        })
                      ])
                    ]
                  })
                ],
                2
              ),
              _vm.reportDetail.reportUid !== 0
                ? _c(
                    "div",
                    [
                      _vm.checkoutDeadline(
                        _vm.reportDetail.reportUid,
                        "forbidChat"
                      )
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("user.forbidChatDeadline")
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.reportDetail.forbidChat,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.reportDetail,
                                      "forbidChat",
                                      $$v
                                    )
                                  },
                                  expression: "reportDetail.forbidChat"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkoutDeadline(
                        _vm.reportDetail.reportUid,
                        "forbidGame"
                      )
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("user.forbidGameDeadline")
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.reportDetail.forbidGame,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.reportDetail,
                                      "forbidGame",
                                      $$v
                                    )
                                  },
                                  expression: "reportDetail.forbidGame"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "postInfo-container" },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "postInfo-container-item",
                                      attrs: {
                                        "label-width": "120px",
                                        label: _vm.$t("user.reports")
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { readonly: true, type: "text" },
                                        model: {
                                          value: _vm.reportDetail.reports,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.reportDetail,
                                              "reports",
                                              $$v
                                            )
                                          },
                                          expression: "reportDetail.reports"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "postInfo-container-item",
                                      attrs: {
                                        "label-width": "120px",
                                        label: _vm.$t("user.forbidden")
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass:
                                            "postInfo-container-item",
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "user.forbidden"
                                            ),
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.reportInfo.type,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.reportInfo,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression: "reportInfo.type"
                                          }
                                        },
                                        _vm._l(_vm.radioButtion2, function(
                                          item,
                                          key
                                        ) {
                                          return _c("el-option", {
                                            key: key,
                                            attrs: {
                                              label: _vm.$t(item.dec),
                                              value: item.value
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "postInfo-container-item",
                                      attrs: {
                                        "label-width": "120px",
                                        label: _vm.$t("operation.timeType")
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass:
                                            "postInfo-container-item",
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "operation.timeType"
                                            ),
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.reportInfo.timeType,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.reportInfo,
                                                "timeType",
                                                $$v
                                              )
                                            },
                                            expression: "reportInfo.timeType"
                                          }
                                        },
                                        _vm._l(_vm.timeTypes, function(
                                          item,
                                          key
                                        ) {
                                          return _c("el-option", {
                                            key: key,
                                            attrs: {
                                              label: _vm.$t(item.dec),
                                              value: item.value
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _vm.reportInfo.timeType === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          staticClass:
                                            "postInfo-container-item",
                                          attrs: {
                                            "label-width": "120px",
                                            label: _vm.$t("operation.period")
                                          }
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 16 } },
                                            [
                                              _c("el-input", {
                                                attrs: { type: "text" },
                                                model: {
                                                  value: _vm.reportInfo.hours,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.reportInfo,
                                                      "hours",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "reportInfo.hours"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("el-col", { attrs: { span: 4 } }, [
                                            _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("user.reportHours")
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    : _c(
                                        "el-form-item",
                                        {
                                          staticClass:
                                            "postInfo-container-item",
                                          attrs: {
                                            "label-width": "120px",
                                            label: _vm.$t("operation.deadline")
                                          }
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticClass: "filter-item",
                                            staticStyle: { width: "300px" },
                                            attrs: {
                                              type: "datetime",
                                              placeholder: _vm.$t(
                                                "operation.selectDatetime"
                                              ),
                                              "value-format":
                                                "yyyy-MM-dd HH:mm",
                                              format: "yyyy-MM-dd HH:mm",
                                              align: "right",
                                              "unlink-panels": ""
                                            },
                                            on: { change: _vm.dataSearch },
                                            model: {
                                              value: _vm.reportInfo.datetime,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.reportInfo,
                                                  "datetime",
                                                  $$v
                                                )
                                              },
                                              expression: "reportInfo.datetime"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "title",
                                        label: _vm.$t("gameSetting.title")
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "gameSetting.inputTitle"
                                          )
                                        },
                                        model: {
                                          value: _vm.reportInfo.title,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.reportInfo,
                                              "title",
                                              $$v
                                            )
                                          },
                                          expression: "reportInfo.title"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "mailContent",
                                        label: _vm.$t("gameSetting.mailContent")
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          placeholder: _vm.$t(
                                            "gameSetting.inputMailContent"
                                          )
                                        },
                                        model: {
                                          value: _vm.reportInfo.mailContent,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.reportInfo,
                                              "mailContent",
                                              $$v
                                            )
                                          },
                                          expression: "reportInfo.mailContent"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "postInfo-container-item",
                                      staticStyle: { "margin-left": "0px" },
                                      attrs: {
                                        size: "medium",
                                        "label-width": "220px",
                                        type: "primary"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.unblockButton(1)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("user.ban")) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "postInfo-container-item",
                                      staticStyle: { "margin-left": "120px" },
                                      attrs: {
                                        size: "medium",
                                        "label-width": "220px",
                                        type: "primary"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.unblockButton(2)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("user.unblock")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("default.operate") } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "0px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleReport(_vm.reportDetail, 1)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("default.pass")) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "120px" },
                      attrs: { type: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.handleReport(_vm.reportDetail, 2)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("default.reject")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }